<template>
  <div>
    <data-filter :get-filter-data="getReport" />
    <loading-report v-if="loadingReport === true" />
    <report-summary
      v-if="reportData.reportData"
      :report-data="reportData.reportData"
      :totals-data="reportData.totals"
    />
    <renewal-status
      v-if="renewalStatusData.reportData"
      :report-data="renewalStatusData.reportData"
      :totals-data="renewalStatusData.totals"
    />
  </div>
</template>

<script>
import DataFilter from '@/views/Reports/Insurance/Renewals/DataFilter.vue'
import ReportSummary from '@/views/Reports/Insurance/Renewals/Summary.vue'
import RenewalStatus from '@/views/Reports/Insurance/Renewals/RenewalStatus.vue'
import LoadingReport from '@/views/Reports/Insurance/Renewals/LoadingReport.vue'

export default {
  name: 'Renewals',
  components: {
    DataFilter,
    ReportSummary,
    RenewalStatus,
    LoadingReport,
  },
  computed: {
    filterData() {
      return this.$store.getters['insuranceRenewalsReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['insuranceRenewalsReport/getReport']
    },
    renewalStatusData() {
      return this.$store.getters['insuranceRenewalsReport/getRenewalStatus']
    },
    loadingReport() {
      return this.$store.getters['insuranceRenewalsReport/getLoading']
    },
  },
  created() {
    this.getPeriods()
    this.getUsers()
  },
  methods: {
    getPeriods() {
      this.$store.dispatch('insuranceRenewalPeriods/getDataList', {
        select: [
          'com_insurance_renewal_periods.id AS id',
          'com_insurance_renewal_periods.title AS title',
        ],
        where: {
          'com_insurance_renewal_periods.status': 1,
        },
      })
    },
    getUsers() {
      this.$store.dispatch('insuranceRenewalsReport/users')
    },
    getReport() {
      if ((this.filterData.sdate && this.filterData.edate) || this.filterData.id_com_insurance_renewal_periods || this.filterData.id_com_user) {
        this.$store.dispatch('insuranceRenewalsReport/report', this.filterData)
      }
    },
  },
}
</script>
